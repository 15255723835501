<div class="select-menu-with-search-input">
  <label *ngIf="data.label" cLabel>
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <ngx-select-dropdown [instanceId]="'dropdown_1'" (change)="data.error = []; setNewValue($event)" tabindex="0"
    [multiple]="false" [(ngModel)]="singleSelect" [config]="select_menu_config" [options]="data.options"
    [disabled]="data.disabled" (searchChange)="searchList($event)"></ngx-select-dropdown>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>