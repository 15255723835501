<div class="checkbox-input">
  <label *ngIf="data.label" cLabel class="d-block mb-3">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>

  <div *ngIf="data.select_all_option" class="border-bottom border-dashed border-0 mb-3 pb-2">
    <c-form-check>
      <input cFormCheckInput id="select-all" type="checkbox" [(ngModel)]="selectAll"
        (change)="data.error = []; toggleAll()"
        [ngClass]="{'border-danger': data.error !== undefined && data.error.length > 0 && data.error[0] !== ''}"
        [disabled]="data.disabled">

      <label cFormCheckLabel for="select-all">{{data.select_all_label || 'Select all'}}</label>
    </c-form-check>
  </div>

  <c-form-check *ngFor="let option of data.options" [inline]="data.inline_options">
    <ng-container>
      <input cFormCheckInput [id]="option.id" [attr.name]="data.name ? data.name : null" type="checkbox"
        [(ngModel)]="option.checked" (change)="data.error = []; updateSelect()"
        [disabled]="option.disabled || data.disabled"
        [ngClass]="{'border-danger': data.error !== undefined && data.error.length > 0 && data.error[0] !== ''}">
    </ng-container>

    <label cFormCheckLabel [for]="option.id">{{ option.label }}</label>
  </c-form-check>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>