import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { BehaviorSubject, Observable, map, switchMap, tap } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    name: string = '';
    email: string = '';

    private roleSubject = new BehaviorSubject<string | null>(null);
    role$ = this.roleSubject.asObservable();

    private allowedLanguagesSubject = new BehaviorSubject<number[] | null>(null);
    allowedLanguages$ = this.allowedLanguagesSubject.asObservable();

    constructor(
        private http: HttpClient,
    ) { }

    // Get admin details
    getAdminProfile(): Observable<{ role: string | null, allowedLanguages: number[] | null }> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.accountPath}/profile`).pipe(
            tap(res => {
                if (res.data) {
                    this.name = res.data.name;
                    this.email = res.data.email;

                    // Assign role
                    const role = res.data.role;
                    this.roleSubject.next(role);

                    // Assign allowed languages
                    if (!res.data.allowed_cms_languages || res.data.allowed_cms_languages.length <= 0) {
                        this.allowedLanguagesSubject.next([1, 4852764, 4938438, 5017431, 4561158, 5028697, 5146990]);
                    } else {
                        this.allowedLanguagesSubject.next(res.data.allowed_cms_languages);
                    }
                }
            }),
            switchMap(() =>
                this.role$.pipe(
                    switchMap(role =>
                        this.allowedLanguages$.pipe(
                            map(allowedLanguages => ({ role, allowedLanguages }))
                        )
                    )
                )
            )
        );
    }
}