import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormFieldModel, InputRelationshipModel } from 'src/app/_models/form-field.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateModalComponent, SelectContentModalComponent } from '../../modal';
import { ContentService } from 'src/app/_services/content.service';
import { Router, RouterLink } from '@angular/router';
import { CMSContentPath } from 'src/app/enum/content.enum';
import { AccountService } from 'src/app/_services/account.service';
import { catchError, of, switchMap, take, timeout } from 'rxjs';

interface selectedContent {
  id: string;
  name: string;
  tags?: string;
  img_url?: string;
  img_type?: string;
  active_period?: string;
  status?: string
}

@Component({
  selector: 'app-content-selector',
  standalone: true,
  imports: [FormModule, CommonModule, ButtonModule, DragDropModule, IconModule, RouterLink],
  templateUrl: './content-selector.component.html',
  styleUrl: './content-selector.component.scss'
})

export class ContentSelectorComponent implements OnInit {
  @Input() config!: FormFieldModel;
  data!: InputRelationshipModel;
  @Output() createButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange = new EventEmitter<{ formControlName: string, value: InputRelationshipModel["selected_data"] }>();

  isContentOrder: boolean = false;
  contentPath: string = '';
  adminAllowedLanguage !: number[];

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    private router: Router,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountService.allowedLanguages$.pipe(
      timeout(5000),
      take(1),
      switchMap(allowedLanguages => {
        if (allowedLanguages) {
          return of({ allowedLanguages });
        } else {
          return this.accountService.getAdminProfile().pipe(take(1));
        }
      }),
      catchError(() => {
        return of({ role: null, allowedLanguages: null });
      })
    ).subscribe(({ allowedLanguages }) => {
      this.adminAllowedLanguage = allowedLanguages!;
    })

    // Check is Content Order
    this.isContentOrder = this.router.url.split('/').includes('order');

    this.data = this.config as InputRelationshipModel;
    this.contentPath = CMSContentPath[this.data.related!.contentType.toUpperCase() as keyof typeof CMSContentPath];

    let tempData = this.config as InputRelationshipModel;
    tempData.selected_data.forEach((data: any, index) => {
      this.contentService.getContent(data.id).subscribe({
        next: res => {
          if (res.data) {
            // Set hasPublish
            this.data.selected_data[index].hasPublished = res.data.api_data.has_published;
          } else {
            // Remove content if not found
            this.data.selected_data.splice(index, 1);
          }
        }
      })
    });

    // Ensure sortable is true by default
    if (this.data.sortable === undefined) {
      this.data.sortable = true;
    }
  }

  contentLink(inode: string) {
    return `${this.contentPath}/${inode}`
  }

  contentListCardDrop(event: CdkDragDrop<selectedContent[]>) {
    if (this.data.selected_data) {
      moveItemInArray(this.data.selected_data, event.previousIndex, event.currentIndex);
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
    }
  }

  onCreateButtonClick() {
    this.openCreateContentModal();
  }

  onSelectButtonClick() {
    this.openSelectContentModal();
  }

  onRemoveButtonClick(id: string) {
    const index = this.data.selected_data.findIndex(data => data.id === id);
    this.data.selected_data.splice(index, 1);
    this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
  }

  openSelectContentModal() {
    const modalRef = this.modalService.open(SelectContentModalComponent, {
      centered: true,
      windowClass: 'select-content-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.relationship = { cardinality: this.data.related!.cardinality, contentType: this.data.related!.contentType, formControlName: this.data.id };
    modalRef.componentInstance.selectedData = this.data.selected_data;
    modalRef.componentInstance.onCreate.subscribe(() => {
      this.openCreateContentModal()
    })
    modalRef.componentInstance.onChange.subscribe((value: any) => {
      const cmsData = value.value.contentData.cms_data;
      const apiData = value.value.contentData.api_data;

      this.data.selected_data.push({
        id: cmsData.inode,
        identifier: cmsData.identifier,
        name: cmsData.title,
        tags: cmsData.tags,
        url: cmsData.coverImage || cmsData.profileImage || '',
        status: cmsData.approvalStatus,
        hasPublished: apiData.has_published
      })
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
    });
    modalRef.componentInstance.onRemove.subscribe((value: any) => {
      this.onRemoveButtonClick(value);
    });
  }

  openCreateContentModal() {
    const modalRef = this.modalService.open(CreateModalComponent, {
      centered: true,
      windowClass: 'create-content-modal',
      modalDialogClass: 'modal-fullscreen-md-down',
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.selectedData = this.data.selected_data;
    modalRef.componentInstance.cms_content_type = this.data.related?.contentType;
    modalRef.componentInstance.onComplete.subscribe((value: any) => {
      const data = value.value.data.cms_data;

      this.data.selected_data.push({
        id: data.inode,
        identifier: data.identifier,
        name: data.title,
        tags: data.tags,
        url: data.coverImage || data.profileImage || '',
        status: data.approvalStatus,
        hasPublished: false
      })
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
      modalRef.dismiss();
    });
  }
}
