<div class="content-selector">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <div *ngIf="data.disabled === false" class="d-flex flex-wrap">
    <button cButton color="primary" size="sm" class="btn-select-image me-2 mb-2"
      (click)="data.error = []; onSelectButtonClick()">
      {{data.select_button_label}}
    </button>
    <button *ngIf="adminAllowedLanguage.includes(1)" cButton variant="outline" size="sm"
      class="btn-file-upload-toggle mb-2" (click)="data.error = []; onCreateButtonClick()">
      {{data.create_button_label}}
    </button>
  </div>
  <div *ngIf="data.selected_data" class="content-list mt-3">
    <ul *ngIf="data.selected_data.length > 0" class="list-unstyled draggable-list" cdkDropList
      (cdkDropListDropped)="contentListCardDrop($event)">

      <li class="draggable-item person" *ngFor="let item of data.selected_data" cdkDrag cdkDragLockAxis="y">
        <div class="draggable-placeholder person" *cdkDragPlaceholder>
          <span></span>
        </div>
        <div class="card bg-gray-light">
          <div class="card-body d-flex flex-wrap position-relative">
            <div *ngIf="data.disabled === false" class="handler">
              <button cButton variant="outline" class="btn-draggable-handler btn-square" cdkDragHandle>
                <svg cIcon name="cilHamburgerMenu"></svg>
              </button>
            </div>
            <div class="content-wrapper">
              <figure class="image-preview">
                <img [src]="item.url ?? '/assets/images/placeholder/img-placeholder-user.webp'">
              </figure>
              <div class="info">
                <a [routerLink]="'/person/'+item.id" class="name" target="_blank"><strong>{{ item.name }}</strong></a>
                <div *ngIf="item.id" class="id fs-smaller gray-500">{{ item.id }}</div>
              </div>
            </div>

            <div *ngIf="data.disabled === false" class="action">
              <button cButton color="danger" class="btn-circle btn-remove" (click)="onRemoveButtonClick(item.id)">
                <svg cIcon name="cilX" size="sm"></svg>
              </button>
            </div>

            <div *ngIf="item.hasPublished || item.status" class="status-label">
              <div *ngIf="!item.hasPublished && item.status === 'DRAFT'" class="label label-draft">
                <i class="cis-pencil me-1"></i> DRAFT
              </div>

              <div *ngIf="item.hasPublished && item.status === 'DRAFT'" class="label label-draft">
                <i class="cis-pencil me-1"></i> UPDATES PENDING SUBMISSION
              </div>

              <div *ngIf="!item.hasPublished && item.status === 'SUBMITTED_FOR_APPROVAL'" class="label label-attention">
                <i class="cis-clock me-1"></i> PENDING APPROVAL
              </div>

              <div *ngIf="item.hasPublished && item.status === 'SUBMITTED_FOR_APPROVAL'" class="label label-attention">
                <i class="cis-clock me-1"></i> UPDATES PENDING APPROVAL
              </div>

              <div *ngIf="item.hasPublished && (item.status === 'APPROVED' || item.status === undefined)"
                class="label label-success">
                <i class="cis-check-circle me-1"></i> APPROVED
              </div>

              <div *ngIf="!item.hasPublished && item.status === 'REJECTED'" class="label label-danger">
                <i class="cis-warning me-1"></i> REJECTED
              </div>

              <div *ngIf="item.hasPublished && item.status === 'REJECTED'" class="label label-danger">
                <i class="cis-warning me-1"></i> UPDATES REJECTED
              </div>

              <div *ngIf="item.status === 'DELETED'" class="label label-danger">
                <i class="cis-warning me-1"></i> DELETED
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <i *ngIf="data.selected_data.length === 0">(No person selected)</i>
  </div>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>