<div class="text-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <input cFormControl type="text" [attr.id]="data.id ? data.id : null" [attr.name]="data.name ? data.name : null"
    [placeholder]="data.placeholder ? data.placeholder : 'Enter here'" [value]="data.value.url"
    [disabled]="data.disabled === undefined ? false : data.disabled" [(ngModel)]="data.value.url"
    (ngModelChange)="data.error = []; change()" [required]="data.required" />
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>

  <iframe *ngIf="videoUrl" id="youtube-iframe" width="100%" height="100%" [src]="videoUrl" frameborder="0"
    referrerpolicy="no-referrer-when-downgrade" hidden
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen></iframe>
</div>