<div *ngIf="data" class="content-selector">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <div *ngIf="data.disabled === false" class="d-flex flex-wrap">
    <button cButton color="primary" size="sm" class="btn-select-image me-2 mb-2"
      (click)="data.error = []; openSelectAssetModal()">
      {{data.select_button_label}}
    </button>
    <button cButton variant="outline" size="sm" class="btn-file-upload-toggle mb-2"
      (click)="data.error = []; fileInput.click()">
      {{data.create_button_label}}
    </button>
    <input type="file" #fileInput hidden (change)="onFileSelected($event)"
      accept=".doc, .docx, .xls, .xlsx, .csv, .zip, .txt, .pdf" multiple>
  </div>
  <div *ngIf="data.selected_data" class="content-list mt-3">
    <ul *ngIf="data.selected_data.length > 0" class="list-unstyled draggable-list" cdkDropList
      (cdkDropListDropped)="contentListCardDrop($event)">

      <li class="draggable-item document" *ngFor="let item of data.selected_data;index as i" cdkDrag
        cdkDragLockAxis="y">
        <div class="draggable-placeholder document" *cdkDragPlaceholder>
          <span></span>
        </div>
        <div class="card bg-gray-light">
          <div class="card-body d-flex flex-wrap position-relative">
            <div *ngIf="data.disabled === false" class="handler">
              <button cButton variant="outline" class="btn-draggable-handler btn-square" cdkDragHandle>
                <svg cIcon name="cilHamburgerMenu"></svg>
              </button>
            </div>
            <div class="content-wrapper">
              <div class="info">
                <a routerLink="/asset-library" [queryParams]="{a:item.id}" class="name"
                  target="_blank"><strong>{{ item.name }}</strong></a>
                <div *ngIf="item.id" class="id fs-smaller gray-500">{{ item.id }}</div>
              </div>
              <div *ngIf="inputDisplayNameConfigArr[i]" class="display-name">
                <app-text-input [config]="inputDisplayNameConfigArr[i]"
                  (onChange)="setFormValue($event,i)"></app-text-input>
              </div>
            </div>

            <div *ngIf="data.disabled === false" class="action">
              <button cButton color="danger" class="btn-circle btn-remove" (click)="onRemoveButtonClick(item.id)">
                <svg cIcon name="cilX" size="sm"></svg>
              </button>
            </div>

          </div>
        </div>
      </li>
    </ul>
    <i *ngIf="data.selected_data.length === 0">(No document selected)</i>
  </div>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>