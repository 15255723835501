<div class="single-content-selector">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <div *ngIf="data.disabled === false" class="d-flex flex-wrap align-items-center select-content-action mb-3">
    <div class="me-2">
      <app-select-menu-input [config]="inputContentTypeConfig"
        (onChange)="data.error = [];contentTypeError = false; onContentTypeSelected($event)"></app-select-menu-input>
    </div>
    <button cButton size="sm" color="primary" class="btn-select-content" [disabled]="!data.selected_content_type"
      (click)="data.error = []; onSelectButtonClick()">
      {{data.select_button_label}}
    </button>
  </div>
  <div *ngIf="data.selected_data && data.selected_data.length > 0" class="content-list mt-3">
    <div class="card bg-gray-light">
      <div class="card-body d-flex flex-wrap position-relative">
        <div class="content-wrapper">
          <figure class="image-preview">
            <img src="/assets/images/placeholder/img-placeholder-image-16by9.webp">
          </figure>
          <div class="info">
            <a [routerLink]="contentLink(data.selected_data[0].id)" class="name"
              target="_blank"><strong>{{ data.selected_data[0].name }}</strong></a>
            <div *ngIf="data.selected_data[0].id" class="id fs-smaller gray-500">{{ data.selected_data[0].id }}</div>
            <div *ngIf="data.selected_data[0].tags" class="tags mt-2">
              <div *ngFor="let tag of data.selected_data[0].tags.split(',')" class="label label-tag">
                {{ tag.trim() }}
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="data.disabled === false" class="action">
          <button cButton color="danger" class="btn-circle btn-remove" (click)="onRemoveButtonClick()">
            <svg cIcon name="cilX" size="sm"></svg>
          </button>
        </div>

        <div *ngIf="data.selected_data[0].hasPublished || data.selected_data[0].status" class="status-label">
          <div *ngIf="!data.selected_data[0].hasPublished && data.selected_data[0].status === 'DRAFT'"
            class="label label-draft">
            <i class="cis-pencil me-1"></i> DRAFT
          </div>

          <div *ngIf="data.selected_data[0].hasPublished && data.selected_data[0].status === 'DRAFT'"
            class="label label-draft">
            <i class="cis-pencil me-1"></i> UPDATES PENDING SUBMISSION
          </div>

          <div *ngIf="!data.selected_data[0].hasPublished && data.selected_data[0].status === 'SUBMITTED_FOR_APPROVAL'"
            class="label label-attention">
            <i class="cis-clock me-1"></i> PENDING APPROVAL
          </div>

          <div *ngIf="data.selected_data[0].hasPublished && data.selected_data[0].status === 'SUBMITTED_FOR_APPROVAL'"
            class="label label-attention">
            <i class="cis-clock me-1"></i> UPDATES PENDING APPROVAL
          </div>

          <div
            *ngIf="data.selected_data[0].hasPublished && (data.selected_data[0].status === 'APPROVED' || data.selected_data[0].status === undefined)"
            class="label label-success">
            <i class="cis-check-circle me-1"></i> APPROVED
          </div>

          <div *ngIf="!data.selected_data[0].hasPublished && data.selected_data[0].status === 'REJECTED'"
            class="label label-danger">
            <i class="cis-warning me-1"></i> REJECTED
          </div>

          <div *ngIf="data.selected_data[0].hasPublished && data.selected_data[0].status === 'REJECTED'"
            class="label label-danger">
            <i class="cis-warning me-1"></i> UPDATES REJECTED
          </div>

          <div *ngIf="data.selected_data[0].status === 'DELETED'" class="label label-danger">
            <i class="cis-warning me-1"></i> DELETED
          </div>
        </div>
      </div>
    </div>
  </div>
  <i *ngIf="data.selected_data.length === 0">(No content selected)</i>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
  <div *ngIf="contentTypeError" class="form-error mt-2">Please select one of the content type to continue.</div>
</div>