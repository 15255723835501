<div class="radio-input">
  <label *ngIf="data.label" cLabel class="d-block mb-3">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <c-form-check *ngFor="let option of data.options" [inline]="data.inline_options" [class.mb-2]="!data.inline_options">
    <input cFormCheckInput [id]="option.id" [name]="data.name" type="radio"
      [disabled]="option.disabled || data.disabled" [value]="option.label" [(ngModel)]="data.value"
      (ngModelChange)="data.error = []; setNewValue($event)">
    <label cFormCheckLabel [for]="option.id">
      {{ option.label }}
      <div *ngIf="option.sublabel" class="sublabel fs-small gray-500">
        <i>{{ option.sublabel }}</i>
      </div>
    </label>
  </c-form-check>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>