<div class="text-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <form [id]="data.id" [formGroup]="summernoteForm">
    <!-- <div #summernote formControlName="html"></div> -->
    <div #summernote [ngxSummernote]="textEditorConfig" [ngxSummernoteDisabled]="data.disabled" formControlName="html">
    </div>
  </form>
  <div class="text-end">Estimate Read Time: {{ totalReadTime ? totalReadTime : '--'}} seconds</div>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>

<!-- BEGIN: No-Wrap Text Popup Modal -->
<ng-template #modalContent>
  <div class="modal-content bg-white px-2">
    <div class="modal-header">
      <h4 class="modal-title">Enter No-Wrap Text</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body px-2">
      <div class="px-2 py-3">
        <input id="textInput" type="text" [formControl]="noWrapFormControl" placeholder="Type your text..."
          class="form-control">
      </div>
    </div>
    <div class="modal-footer d-flex w-100">
      <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
      <button type="button" [disabled]="noWrapFormControl.value === ''" class="btn btn-primary"
        (click)="confirm()">Confirm</button>
    </div>
  </div>
</ng-template>
<!-- END: No-Wrap Text Modal -->