<div class="text-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}} <sup class="text-danger" *ngIf="data.required">*</sup>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <textarea cFormControl type="text" [attr.id]="data.id ? data.id : null" [attr.name]="data.name ? data.name : null"
    [placeholder]="data.placeholder ? data.placeholder : 'Enter here'" [value]="data.value"
    [disabled]="data.disabled === undefined ? false : data.disabled" rows="4" [required]="data.required"
    [(ngModel)]="data.value" (ngModelChange)="data.error = []; setNewValue($event)"
    [ngClass]="{'border-danger':data.error!== undefined && data.error.length > 0}"></textarea>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
  <div *ngIf="data.error && data.error.includes('pattern')" class="form-error mt-2">{{data.name}} has exceed the
    character limit.</div>
</div>